<template>
  <div class="">
    <div class="d-flex flex-column align-center mt-4">
      <img
        :src="require(`@/assets/images/${logo.sm}`)"
        width="60"
        height="60"
      />
    </div>

    <div class="d-flex flex-column align-center mt-6">
      <div class="text-h6">Welcome back!</div>
      <div class="">Please sign in to continue</div>
    </div>

    <v-form lazy-validation ref="form" class="mt-10">
      <v-text-field
        v-model="form.email"
        :rules="[$rules.required, $rules.email]"
        :disabled="loading"
        type="email"
        placeholder="you@company.com"
        prepend-inner-icon="mdi-email-outline"
        class="rounded-lg"
        height="48px"
        outlined
      ></v-text-field>

      <v-text-field
        v-model="form.password"
        @keyup.enter="submit"
        @click:append="showPassword = !showPassword"
        :rules="[$rules.required]"
        :disabled="loading"
        :type="!showPassword ? 'password' : 'text'"
        placeholder="super secret password"
        prepend-inner-icon="mdi-lock-outline"
        :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
        class="rounded-lg"
        height="48px"
        outlined
      ></v-text-field>

      <div
        class="primary--text mb-3 text-right font-weight-bold pointer"
        @click="navigateToResetPasswordRoute"
      >
        Forget password?
      </div>

      <v-btn
        @click="submit"
        :loading="loading"
        color="primary"
        class="rounded-lg font-weight-bold"
        height="48px"
        block
      >
        Sign in
      </v-btn>
    </v-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import authToken from "@/common/authToken";

export default {
  data() {
    return {
      loading: false,
      showPassword: false,
      form: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      logo: "getLogo",
    }),
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const onSuccess = (res) => {
          authToken.setToken(
            res.data.data.access_token,
            res.data.data.refresh_token
          );
          this.$router.push({ name: "dashboard" });
        };

        const onFinally = () => {
          this.loading = false;
        };

        const data = {};
        data["email"] = this.form.email;
        data["password"] = this.form.password;
        data["platform"] = "web";

        return this.$request(this.$keys.POST, this.$urls.auth.login, {
          data,
          onSuccess,
          onFinally,
          isTokenRequired: false,
        });
      }
    },

    navigateToResetPasswordRoute() {
      this.$router.push({ name: "resetPassword" });
    },
  },
};
</script>
